import React from "react"
import {Container, Row, Col, Table} from 'react-bootstrap';
import {css} from '@emotion/core';

import Layout, {bp} from '../../components/layout';
import SubCover from '../../components/sub-cover';
import PageContent from '../../components/page-content';
import GreenCircle from './green-circle.png';

const plans = [
  {
    title: 'コンサルティングプラン',
    description: '依頼者様のイメージを自由にお話いただき、ブレインストーミングを含めたミーティングを行って方向性や可能性を探っていきます。\n※このプランには開発や制作の工程は含まれません。',
    table: {
      header: ['プランA1', 'プランA2', 'プランA3'],
      rows: [
        ['コンサルティング,コンサルティング\n専門知識の提供', '○', '○', ''],
        ['調査,事案に関する調査', '○', '', ''],
        ['ソリューション提案,調査を踏まえた提案\n（提案書の作成）', '○', '○', '○']
      ]
    }
  },
  {
    title: '制作プラン',
    description: '依頼者様のイメージをもとに創造的なソリューションを提案、システムとコンテンツを統合的に制作していきます。',
    table: {
      header: ['プランB1,システム設計・制作', 'プランB2,システム設計・制作・サービスや商品の開発（cotonの技術を利用）'],
      rows: [
        ['準備・基礎調査,コンサルティング・調査・提案書の作成', '○', '○'],
        ['プロトタイプ開発,ソリューションの基礎部分の設計・開発・検証', '○', '○'],
        ['制作・開発,納品物の制作とテスト', '○', '○'],
        ['公開・発表・イベント実施・商品化,完成品の納品・公開発表など', '○', '○'],
        ['メンテナンス,必要に応じて実施', '○', '○']
      ]
    },
    note: '※上記に加え、coton技術ライセンス料を設定させていただく場合があります。'
  },
  {
    title: '研究開発プラン',
    description: '研究プレゼンテーションの制作から商業・学術イベントでの発表、研究をもとにした製品開発まで、ご相談に応じながら共同研究開発を進めていきます。',
    table: {
      header: ['プランC1,依頼者+cotonによる研究開発型プロジェクト', 'プランC2,依頼者+coton+大学研究室の3者による研究開発型プロジェクト'],
      rows: [
        ['萌芽期,目標に対するアイデアの検討・調査', '○', '○'],
        ['準備期,調査・予備実験', '○', '○'],
        ['プロトタイプ開発,研究の実装準備としての基礎設計・開発・検証', '○', '○'],
        ['制作・開発,納品物の制作とテスト', '○', '○'],
        ['イベント（コマーシャル）,商業的な場での発表', '○', ''],
        ['イベント（アカデミック）,アート作品・展示・研究発表', '', '○'],
        ['製品化（ライセンス契約に基づく）,製品のパッケージ化と販売', '', '○']
      ]
    },
    note: '※ライセンス契約のかたちをとる必要が生じる場合は、ステークホルダー間で事前にご相談の上、ケースに応じた契約をさせていただきます。'
  }
];

const createPlan = ({title, description, table, note}) => {
  const createRowHeader = cell => {
    const [title, description] = cell.split(',');
    return (
      <div>
        <h5 css={css`
          margin: 5px 0 10px 0;
        `}>{title}</h5>
        {description && (
          description.split('\n').map((text, i) => <div key={i}>{text}</div>)
        )}
      </div>
    );
  };
  const {header, rows} = table;
  return (
    <Container key={title} fluid className="no-padding">
      <Row>
        <Col md={4}>
          <h4 className="green-border-left">{title}</h4>
          <p>{description}</p>
        </Col>
        <Col md={8}>
          <Table
            css={css`
              border: 1px solid #707070;
              th,tr,td {
                border: 1px solid #707070!important;
              }
              width: 100%;
              font-size: 12px;
              h5 { font-size: 14px; }
              margin-top: 0;
              ${bp.sm} {
                font-size: inherit;
                h5 { font-size: inherit; }
                margin-top: 45px;
              }
            `}
          >
            <thead>
              <tr>
                <th aria-label="header-left-column"></th>
                {
                  header.map(h => {
                    const [title, description] = h.split(',');
                    return (
                      <th key={h}
                        css={css`
                          text-align: center;
                          vertical-align: top!important;
                          width: ${35 - 5 * header.length}%;
                        `}
                      >
                        {/* header line break point */}
                        <h5>{title}</h5>
                        {description}
                      </th>
                    );
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                rows.map((row, i) => (
                  <tr key={i}>
                    {
                      row.map((cell, i) => (
                        i === 0 ?
                          <td key={i} css={css`
                            
                          `}>{createRowHeader(cell)}</td> :
                          <td key={i} css={css`
                            vertical-align: middle!important;
                          `}>
                            {
                              cell && (
                                <img
                                  src={GreenCircle}
                                  css={css`
                                    display: block;
                                    margin: auto;
                                    width: 32px;
                                    ${bp.xs} {
                                      width: 64px;
                                    }
                                  `}
                                  alt="circle"
                                />
                              )
                            }
                          </td>
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>
          </Table>
          {note}
        </Col>
      </Row>
    </Container>
  );
};

export default () => (
  <Layout pageTitle="開発・制作">
    <SubCover
      title="開発・制作"
      caption="cotonのしごと"
      image="sub-cover.jpg"
    />

    <div css={css`
      margin-top: 30px;
    `}>
        
      <PageContent>

        <div
          className="green-border-left"
          css={css`
            margin-bottom: 50px;
          `}
        >
          <h3>開発・制作</h3>
          cotonでは以下の各種業務を承っております。
        </div>

        <ul>
          <li>デジタル技術における音と振動、音響心理、音楽心理に特化した技術の企画、 開発及び販売</li>
          <li>アプリケーションの企画、開発及び販売</li>
          <li>特許技術の開発及び活用</li>
          <li>心理とデータの関連性に関する独自の根拠研究のサービス</li>
          <li>各種マーケティングに関する情報の収集企画に関する業務</li>
          <li>産学連携による研究に基づく事業</li>
          <li>教育事業の企画、運営及びコンサルティング</li>
          <li>インターネットを使った学習サービスの開発販売</li>
          <li>デジタル技術を活用した研究、商材の企画、開発及び販売</li>
        </ul>
        
      </PageContent>
      <PageContent className="background-gray">

        <h3>ご依頼プラン</h3>

        {plans.map(createPlan)}

      </PageContent>
      
    </div>

  </Layout>
);
