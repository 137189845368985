import React from 'react';
import {css} from '@emotion/core';
// import OverlayGreen from '../components/overlay-green';
import {bp} from '../components/layout';

export default ({title, caption, image}) => (
  <>
    {/* <OverlayGreen css={css`
      min-height: 375px;
      ${bp.sm} {
        min-height: 600px;
      }
    `}/> */}
    <div
      css={css`
        min-height: 375px;
        background-size: cover;
        background-position: center;
        font-weight: 600;
        color: white;
        ${bp.sm} {
          min-height: 600px;
        }
      `}
      style={{
        backgroundImage: `url(/${image})`
      }}
    >
      <div css={css`
        padding-top: 120px;
        font-size: 55px;
        text-align: center;
        ${bp.sm} {
          font-size: 120px;
          padding-top: 180px;
        }
      `}>
        {title}
      </div>
      <div css={css`
        font-size: 16px;
        text-align: center;
        word-break: keep-all;
        padding: 0;
        ${bp.sm} {
          font-size: 24px;
        }
      `}>
        <div
          dangerouslySetInnerHTML={{__html:
            caption.replace(/,/g, '<br />')
          }}
        >
        </div>
      </div>
    </div>
  </>
);
